<template>
<div class="Robotics">
  <div class="inner-banner" style="margin-bottom: 0px;">
    <div class="overlay">
      <div class="container clearfix">
	<h2>Robotics</h2>
	<ul>
	  <li>
	    <router-link to="/" class="nav-link"> Home </router-link>
	  </li>
	  <li>/</li>
	  <li>Robotics</li>
	</ul>
      </div> <!-- /.container -->
    </div> <!-- /.overlay -->
  </div> <!-- /.inner-banner -->
  
  
  <div class="our-portfolio portfolio-grid-v3" style="padding-top:80px; background: #f6f6f6;">
    <div class="container">
      <!-- End of .mixitUp-menu -->
      
      <div class="row" id="mixitUp-item">
	
	<div class="col-lg-6 col-sm-6 col-12 mix personal other" style="display: inline-block;" data-bound="">
	  <div class="single-item">
	    <div class="image-box">
	      <div class="img company-splash"><img src="images/p3.jpg" alt=""></div>
	      <div class="overlay">
		<div class="overlay-text">
		  <h4><a target="_blank" href="https://pillohealth.com/">Pillo Health</a></h4>
		  <p>Business Strategy, Fund Raising, Operational
		  Execution for a unique social medication
		  dispenser.</p>  
		</div> <!-- /.overlay-text -->
	      </div> <!-- /.overlay -->
	    </div> <!-- /.image-box -->
	  </div> <!-- /.single-item -->
	</div> <!-- /.col-md-6 -->
	
	<div class="col-lg-6 col-sm-6 col-12 mix carloan" style="display: inline-block;" data-bound="">
	  <div class="single-item">
	    <div class="image-box">
	      <div class="img company-splash"><img src="images/p4.jpg" alt=""></div>
	      <div class="overlay">
		<div class="overlay-text">
		  <h4><a target="_blank" href="https://www.jibo.com/">Jibo</a></h4>
		  <p>Technical Architecture, Operational Execution,
		  Fund Raising, Business Strategy for the first and
		  leading social robot.</p>
		</div> <!-- /.overlay-text -->
	      </div> <!-- /.overlay -->
	    </div> <!-- /.image-box -->
	  </div> <!-- /.single-item -->
	</div> <!-- /.col-md-6 -->
	
	<div class="col-lg-6 col-sm-6 col-12 mix carloan" style="display: inline-block;" data-bound="">
	  <div class="single-item">
	    <div class="image-box">
	      <div class="img company-splash"><img src="images/smartlab.jpg" alt=""></div>
	      <div class="overlay">
		<div class="overlay-text">
		  <h4><a target="_blank" href="https://www.artificial.com/">Artificial</a></h4>
		  <p>Product Definition for AI automation of biology labs.</p>
		</div> <!-- /.overlay-text -->
	      </div> <!-- /.overlay -->
	    </div> <!-- /.image-box -->
	  </div> <!-- /.single-item -->
	</div> <!-- /.col-md-6 -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div>
</div>
</template>


<script>
export default {
    title: 'Robotics - Ten Factor Growth'
}
</script>

<style scoped>
@import './company-splash.css';

</style>

